<!--图片标记-->
<template>
  <div
      class="container"
      v-loading="isLoading"
      element-loading-text="数据加载中..."
      element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!--顶部工具栏-->
    <div class="top-tool">
      <!--左上角盒子-->
      <div class="top-left-div">
        <div
            v-for="item in colorList"
            :key="item.color"
            :class="[
            currentColor.color === item.color ? 'color-div-a' : 'color-div',
          ]"
            :style="{ backgroundColor: item.color }"
            @click="changeColor(item)"
        ></div>
        <!--拾色器按钮-->
        <div class="getcolor-div" title="拾色器">
          <img :src="colorImg" alt=""/>
          <input id="colorInp" type="color" class="color-inp"/>
        </div>
        <el-form-item style="margin-top: 10px;margin-left: 50px">
          <template v-slot:label>
            <span style="color: #ffffff">展示楼层</span>
          </template>
          <el-select v-model="preview_id" placeholder="请选择楼层号" style="width: 100px" @change="handleChangeFloor">
            <el-option
                v-for="item in floor"
                :key="item"
                :value="item + '层'"
            />
          </el-select>
        </el-form-item>
      </div>
      <!--右上角盒子-->
      <div class="top-right-div">
        <el-tooltip
            v-for="item in modelList"
            :key="item.model"
            class="item"
            effect="dark"
            :content="item.name + '：' + item.msg"
            placement="bottom"
        >
          <el-button
              :class="[
              currentModel.model === item.model ? 'model-btn-a' : 'model-btn',
            ]"
              type="primary"
              @click="changeModel(item)"
          ><img :src="item.img"
                alt=""/></el-button>
        </el-tooltip>
        <el-tooltip
            class="item"
            effect="dark"
            content="保存数据"
            placement="bottom"
        >
          <el-button type="primary" class="clear-btn" @click="handleSubmit">
            保存
          </el-button>
        </el-tooltip>
      </div>
    </div>

    <!--画布盒子-->
    <div class="middle-div">
      <div class="canvas-div">
        <canvas id="myCanvas" width="1080" height="768"></canvas>
      </div>
    </div>
    <el-dialog title="信标绑定" v-model="ibeaconDialog" width="50%" :show-close="false"
               :close-on-press-escape="false" :close-on-click-modal="false">
      <el-form label-width="100px">
        <el-form-item label="设备ID">
          <el-input v-model="beaconForm.device_id"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="beaconForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button @click="handleBindBeacon" type="primary">绑 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import CanvasSelect from 'canvas-select'
import floor from "@/model/floor";
import tool from "@/utils/tool";

export default {
  props: {},
  components: {},
  data() {
    return {
      isLoading: false, //加载状态
      ibeaconDialog: false,
      beaconForm: {
        uuid: '',
        x: '',
        y: '',
        device_id: '',
        remark: ''
      },
      colorImg: require("../../assets/img/color.png"), //拾色器图标
      //颜色列表
      colorList: [
        // {color: "#ffffff"},
        {color: "#000000"},
        {color: "#ff7070"},
        {color: "#00c79f"},
        {color: "#9094fe"},
      ],
      currentColor: {}, //当前选中颜色
      //模式列表
      modelList: [
        {
          model: "point",
          name: "添加定位器",
          value: 3,
          img: require("../../assets/img/point.png"),
          msg: "左键点击绘制",
        },
        {
          model: "beeline",
          name: "折线模式",
          value: 4,
          img: require("../../assets/img/beeline.png"),
          msg: "鼠标左键单击添加点，双击完成绘制",
        },
        {
          model: "rectangle",
          name: "矩形模式",
          value: 1,
          img: require("../../assets/img/rectangle.png"),
          msg: "按住鼠标左键拖动完成绘制",
        },
        {
          model: "polygon",
          name: "多边形模式",
          value: 2,
          img: require("../../assets/img/polygon.png"),
          msg: "鼠标左键单击添加点，双击闭合完成绘制",
        },
        {
          model: "circle",
          name: "圆形模式",
          value: 5,
          img: require("../../assets/img/circle.png"),
          msg: "左键拖拽绘制",
        },
      ],
      currentModel: {}, //当前模式
      output: [],
      ibeaconList: [],
      floor: 0,
      building_id: 0,
      preview_id: 1,
      floorList: {}
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.building_id = this.$route.query.id
    this.floor = parseInt(this.$route.query.number)
    this.isLoading = true
    this.loadFloor()
  },
  methods: {
    async loadFloor() {
      const ret = await floor.getRoomByNumber({
        building_id: this.building_id,
        number: this.preview_id
      })
      if (ret.code === 200) {
        this.floorList = ret.data
        this.output = tool.isEmpty(this.floorList.maps) ? [] : JSON.parse(this.floorList.maps)
        this.ibeaconList = ret.ble
      }
      this.initFloor()
    },
    handleChangeFloor() {
      this.loadFloor()
    },
    initFloor() {
      const me = this
      this.currentColor = this.colorList[0]
      this.instance = new CanvasSelect(
          "#myCanvas",
          'https://adminapi.yztc2022.com/map-bg.jpg'
      )
      this.instance.labelMaxLen = 10
      this.instance.strokeStyle = this.currentColor.color
      // 图片加载完成
      this.instance.on("load", (src) => {
        console.log("image loaded", src)
      })
      this.instance.setData(this.output)
      this.isLoading = false
      // 添加
      this.instance.on("add", (info) => {
        if (info.type === 3) {
          me.beaconForm.uuid = info.uuid
          me.beaconForm.x = info.coor[0]
          me.beaconForm.y = info.coor[1]
          me.ibeaconList.push(JSON.parse(JSON.stringify(me.beaconForm)))
          me.beaconForm.device_id = ''
          me.beaconForm.remark = ''
          me.currentModel = ''
          me.instance.createType = ''
        }
        // this.output.push(JSON.parse(JSON.stringify(info)))
      })
      // 删除
      this.instance.on("delete", (info) => {
        if (info.type === 3) {
          this.ibeaconList.forEach((v, k) => {
            if (v.uuid === info.uuid) {
              this.ibeaconList.splice(k, 1)
            }
          })
        }
      })
      // 选中
      this.instance.on("select", () => {
      })
      this.instance.on("updated", (result) => {
        this.output = JSON.parse(JSON.stringify(result))
      })
    },
    handleBindBeacon() {
      this.ibeaconDialog = false
    },
    handleClose() {
      this.ibeaconDialog = false
      this.currentModel = ''
      this.instance.createType = ''
    },
    //切换画笔颜色
    changeColor(data) {
      this.currentColor = data
      this.instance.strokeStyle = data.color
    },
    //切换画笔模式
    changeModel(data) {
      if (this.instance.createType === data.value) {
        this.instance.createType = ''
      } else {
        this.instance.createType = data.value
      }
      if (this.currentModel === data) {
        this.currentModel = ''
      } else {
        this.currentModel = data
      }
      if (this.currentModel.value === 3) {
        this.ibeaconDialog = true
      }
    },
    handleSubmit() {
      this.$confirm("确定保存当前数据", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            this.output.forEach((v) => {
              v.active = false
              v.creating = false
              v.dragging = false
            })
            const ret = await floor.handle({
              building_id: this.building_id,
              number: this.preview_id,
              maps: JSON.stringify(this.output),
              beacons: JSON.stringify(this.ibeaconList)
            })
            if (ret.code === 200) {
              this.$message.success(ret.message)
            } else {
              this.$message.error(ret.message)
            }
          })
          .catch(() => {
          })
    }
  }
};
</script>
<style scoped lang="scss">
.container {
  user-select: none;
  height: 100%;
  width: 100%;

  .top-tool {
    height: 50px;
    display: flex;
    justify-content: space-between;
    background-color: rgba(39, 55, 70, 1);

    .top-left-div {
      display: flex;

      .color-div-a,
      .color-div {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        margin: 5px 10px;

        &:hover {
          cursor: pointer;
          box-shadow: 0px 0px 10px #fff;
        }
      }

      .color-div-a {
        box-shadow: 0px 0px 10px #fff;
      }

      .getcolor-div {
        position: relative;
        margin: 5px 10px;

        img {
          position: absolute;
          top: 0;
          height: 40px;
          width: 40px;
          border-radius: 50%;
        }

        input {
          z-index: 10;
          border-radius: 50%;
          position: absolute;
          top: 0;
          background-color: transparent;
          height: 40px;
          width: 40px;
          opacity: 0;
          border: 0px;
          cursor: pointer;
        }
      }

      .el-form-item__label {
        color: #ffffff !important;
      }
    }

    .top-right-div {
      margin: 0px;
      display: flex;

      .model-btn,
      .model-btn-a {
        background-color: #455769;
        border: 0px;
        padding: 9px 15px;
        margin: auto 10px;

        img {
          height: 20px;
          width: 20px;
        }
      }

      .model-btn-a {
        border: 0px;
        background-color: rgba(255, 255, 255, 0.5);
      }

      .download-btn,
      .clear-btn {
        margin: auto 10px;
        //padding: auto;
      }

      .upload-btn {
        margin: auto 10px;
        height: 40px;
        width: 50px;
        padding: 0px;
        position: relative;

        input {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 10;
          width: 100%;
          height: 100%;
          opacity: 0;
          border: 0px;
        }
      }
    }
  }

  .middle-div {
    width: 100%;
    height: calc(100% - 50px);

    .canvas-div {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      background-color: #000000;

      #myCanvas {
        background-color: rgba(255, 255, 255);
        //position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 9%;
        margin-top: 7%;
      }
    }
  }

  .msg-div {
    position: absolute;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.5);
    color: #000;
    padding: 5px 20px;
  }
}
</style>
